<template>
    <div>
        <v-toolbar
                dense
                color="primary darken-2"
                dark
                extended
                extension-height="210"
                flat
        >
            <v-toolbar-title class="mx-auto " slot="extension">
                <span v-if="$store.state.isAuthenticated">Welcome <b>{{$store.state.user_info["user"]["username"]}} 😃</b>, to PettyCash: Savings</span>
                <span v-else >Welcome 😃 to <b>PettyCash: Savings</b>,</span>
                <h2 class="saving_heading">We make your coins become a fortune.</h2>
                <v-layout row >
                    <v-flex wrap xs2 sm3 md2 lg2 >
                        <v-btn outline  fab color="white" class="outine-2">
                            <i class="fas fa-coins fa-3x"></i>
                        </v-btn>
                    </v-flex>
                    <v-flex wrap xs2 sm2 md2 lg2 >
                        <v-btn flat  fab color="white">
                            <i class="fas fa-plus fa-2x"></i>
                        </v-btn>
                    </v-flex>
                    <v-flex wrap xs2 sm2 md2 lg2 >
                       <v-btn outline  fab color="white" class="outine-2">
                            <i class="fas fa-piggy-bank fa-3x"></i>
                        </v-btn>
                    </v-flex>
                    
                    <v-flex wrap xs2 sm2 md2 lg2 >
                        <v-btn flat  fab color="white">
                            <i class="fas fa-arrow-right-long fa-2x"></i>
                        </v-btn>
                    </v-flex>
                    
                    <v-flex wrap xs2 sm2 md3 lg3 >
                        <v-img
                            :src="require('@/assets/money_bag_bill_coins_color.png')"
                            aspect-ratio="2"  
                            contain
                            height="90"
                            width="120"
                        ></v-img>
                    </v-flex>
                </v-layout>
            </v-toolbar-title>
        </v-toolbar>

        <v-container class="mt-5">
            <v-layout row>
                <v-flex xs12 md10 offset-md1 lg10 offset-lg1>
                    <v-card flat class="card--flex-toolbar" color="transparent">
                        <v-container fluid grid-list-lg>
                            <v-layout row wrap>
                                <v-flex xs12 class="mt-0 pt-0 mb-2">
                                    <h3 class="white--text ">Explore Petty Ca$h Savings</h3>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex xs12 sm6 md6 lg4 v-for="item in links" :key="item.id" class="pa-2">
                                    <v-hover>
                                        <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 12 : 4}`" style="border-radius: 5px; font-size: small" :to="item.link">
                                            <v-layout row >
                                                <v-flex xs8>
                                                    <v-btn fab :color="item.color" class="mt-2 ml-3 mb-0 mr-2 white--text">
                                                        <i :class="item.icon"></i>
                                                    </v-btn>
                                                </v-flex>
                                                <v-flex xs4 v-if="!(item.title == 'Legal Bindings')" class="text-xs-right">
                                                    <v-menu bottom right >
                                                        <v-btn slot="activator" icon flat fab color="mt-2 ml-3 mb-0" v-on:click.prevent="activateMenu(item.title)">
                                                            <i class="fa-solid fa-ellipsis-vertical fa-2x"></i>
                                                        </v-btn>
                                                        <v-list class="grey lighten-4">
                                                        <v-list-tile v-for="menuLink in menuItems" :key="menuLink.title" :to="menuLink.link">
                                                            <v-list-tile-title>{{ menuLink.title }}</v-list-tile-title>
                                                        </v-list-tile>
                                                        </v-list>
                                                    </v-menu>
                                                </v-flex>
                                            </v-layout>
                                            <v-card-title primary-title class="pb-0 mt-0 pt-1" style="color: #424242">
                                                <div>
                                                    <h5 class="subheading pl-0 pb-0 mb-0"><b>{{item.title}}</b></h5>
                                                    <div class="caption mt-0 mb-0 pb-2">{{item.description}}</div>
                                                </div>
                                            </v-card-title>
                                        </v-card>
                                    </v-hover>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                links: [
                    {
                        id: '1',
                        title: 'Campaigns',
                        description: 'Create, view and save objectively with discipline specific campaigns',
                        color: 'orange',
                        icon: 'fa-solid fa-money-check fa-2x',
                        link: '/savings/campaigns/active',
                        add_link: false
                    },
                    {
                        id: '2',
                        title: 'Loans',
                        description: 'Request, view and get credit loans now with Petty Cash.',
                        color: 'teal',
                        icon: 'fa-solid fa-hand-holding-usd fa-2x',
                        link: '/savings/loans/active',
                        add_link: false
                    },
                    {
                        id: '3',
                        title: 'Transactions',
                        description: 'View all your previous deposits and withdrawals so far with our savings.',
                        color: 'teal',
                        icon: 'fa-solid fa-exchange fa-2x',
                        link: '/savings/transactions/all',
                        add_link: false
                    },
                    {
                        id: '4',
                        title: 'Withdrawals',
                        description: 'Request a withdrawal and get your money to work on your objective',
                        color: 'blue',
                        icon: 'fa-solid fa-cash-register fa-2x',
                        link: '/savings/withdrawals/request',
                        add_link: false
                    },
                    {
                        id: '5',
                        title: 'Contact Us',
                        description: 'Get in contact with us and if you have any worries',
                        color: 'green',
                        icon: 'fa-solid fa-address-card fa-2x',
                        link: '/savings/contact_us/all',
                        add_link: false
                    },
                    {
                        id: '6',
                        title: 'Legal Bindings',
                        description: 'Learn of the rules and ways of saving with Petty Cash',
                        color: 'purple',
                        icon: 'fa-solid fa-file-contract fa-2x',
                        link: '/savings/legal_binding/all',
                        add_link: false
                    }
                ],
                menuItems: [

                ],
            }
        },
        mounted(){
            // executes these after the page has been mounted
            document.title = "PettyCash | Savings"
        },
        methods:{
            activateMenu(title){
                if(title == "Campaigns"){
                    this.menuItems = [
                        {
                            "title": "Active",
                            "link": "/savings/campaigns/active",
                        },
                        {
                            "title": "Completed",
                            "link": "/savings/campaigns/completed",
                        },
                        {
                            "title": "Interrupted",
                            "link": "/savings/campaigns/interrupted",
                        },
                    ]
                }
                else if(title == "Transactions"){
                    this.menuItems = [
                        {
                            "title": "All",
                            "link": "/savings/transactions/all",
                        },
                        {
                            "title": "Deposits",
                            "link": "/savings/transactions/deposits",
                        },
                        {
                            "title": "Withdrawals",
                            "link": "/savings/transactions/withdrawals",
                        },
                    ]
                }
                else if(title == "Withdrawals"){
                    this.menuItems = [
                        {
                            "title": "Request",
                            "link": "/savings/withdrawals/request",
                        },
                        {
                            "title": "Fees",
                            "link": "/savings/withdrawals/fees",
                        },
                        {
                            "title": "Information",
                            "link": "/savings/withdrawals/info",
                        },
                    ]
                }
                else if(title == "Contact Us"){
                    this.menuItems = [
                        {
                            "title": "All Chats",
                            "link": "/savings/contact_us/all",
                        },
                        {
                            "title": "Create New Chat",
                            "link": "/savings/contact_us/new_ticket",
                        },
                    ]
                }
            }
        },

    }
</script>

<style scoped>
    .outine-2 {
        border: 2px solid white;
    }
    @media (max-width: 499px) {
        .saving_heading{
            font-size: 12px
        }
    }

    .card--flex-toolbar {
        margin-top: -124px;
    }

    .learn-more-btn {
        text-transform: initial;
        text-decoration: underline;
    }
</style>
